.styled-scrollbar-track::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
  margin: 3px;
}

.styled-scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.styled-scrollbar-track-dark::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #000000;
  margin: 3px;
}

.styled-scrollbar-dark::-webkit-scrollbar {
  width: 12px;
  background-color: #000000;
}

.styled-scrollbar-thumb::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
